import React from "react"
import { Button, Form } from "antd"
import usePaymentFormItems from "./usePaymentFormItems"
import { useTranslation } from "react-i18next"

const EditPaymentForm = ({ onFinish, initialValues }) => {
  const formItems = usePaymentFormItems()
  const { t } = useTranslation()
  return (
    <Form onFinish={onFinish} layout={"vertical"} initialValues={initialValues}>
      {formItems}

      <div className="form-submit-button-wrapper">
        <Form.Item>
          <Button
            size="large"
            className="green"
            type="primary"
            htmlType="submit"
          >
            {t("button:update")}
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
}

export default EditPaymentForm
