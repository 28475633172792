import React, { useState } from "react"
import PageRoot from "../../../../components/PageRoot"
import EditPaymentForm from "../../../../components/forms/EditPaymentMethodForm"
import { Spin } from "antd"
import {
  translatePath,
  uiHandleError,
  uiHandleSuccess,
} from "../../../../utils"
import QueryString from "query-string"
import LoadingScreen from "../../../../components/LoadingScreen"
import ErrorScreen from "../../../../components/ErrorScreen"
import { gql, useQuery } from "@apollo/client"
import ContainerWithPadding from "../../../../components/ContainerWithPadding"
import { navigate } from "gatsby"
import { useTranslation } from "react-i18next"
import useBraintree from "../../../../hooks/useBraintree"

const GET_USER_BILLING_PROFILES_QUERY = gql`
  query getBillingProfile($id: uuid!) {
    billing_profiles_by_pk(id: $id) {
      billing_address
      braintree_customer_id
      business_address
      email
      firstname
      lastname
      phone_number
      tax_name
      user_id
      vat_number
      tax_code
    }
  }
`

const EditPaymentMethodPage = ({ location }) => {
  const { t } = useTranslation()
  const { id } = QueryString.parse(location.search)
  const { updateUserPaymentMethod } = useBraintree()
  const [loading, setLoading] = useState(false)
  const {
    loading: billingProfileLoading,
    error,
    data,
  } = useQuery(GET_USER_BILLING_PROFILES_QUERY, {
    variables: { id },
    fetchPolicy: "network-only",
  })
  const pageTitle = t("label:editPaymentMethod")

  const handleBillingFormSubmit = async values => {
    setLoading(true)
    try {
      await updateUserPaymentMethod({
        ...values,
        id,
      })

      uiHandleSuccess({
        action: () => {
          navigate(translatePath("/me/billing/payment-method/list"))
        },
        message: t("message:paymentMethodUpdated"),
      })
    } catch (error) {
      uiHandleError({ error })
    }
    setLoading(false)
  }

  if (billingProfileLoading) {
    return (
      <PageRoot title={pageTitle} showTitle>
        <LoadingScreen />
      </PageRoot>
    )
  }

  if (error) {
    return (
      <PageRoot title={pageTitle} showTitle>
        <ErrorScreen error={error} />
      </PageRoot>
    )
  }

  const initialValues = {
    ...data.billing_profiles_by_pk,
    extra_billing_data: !!data?.billing_profiles_by_pk?.tax_name,
  }

  return (
    <PageRoot title={pageTitle} showTitle>
      <ContainerWithPadding>
        <Spin spinning={loading}>
          <EditPaymentForm
            onFinish={handleBillingFormSubmit}
            initialValues={initialValues}
          />
        </Spin>
      </ContainerWithPadding>
    </PageRoot>
  )
}

export default EditPaymentMethodPage
